const individual = "INDIVIDUAL";
const institute = "INSTITUTE";
const faculty = "FACULTY";
const admin = "ADMIN";
const candidate = "CANDIDATE";
const pdfIcon = "https://cdn.wseinfratech.com/pdf_icon.svg";
const wordIcon = "https://cdn.wseinfratech.com/word_icon.svg";

export const constants = {
    individual, institute, faculty, admin, candidate, pdfIcon, wordIcon
}