export const GET_TRENDING_PENDING = "getTrendingPending";
export const GET_MORE_TRENDING_PENDING = "getMoreTrendingPending";
export const GET_TRENDING_SUCCESS = "getTrendingSuccess";
export const GET_MORE_TRENDING_SUCCESS = "getMoreTrending";

export const GET_LATEST_PENDING = "getLatestPending";
export const GET_MORE_LATEST_PENDING = "getMoreLatestPending";
export const GET_LATEST_SUCCESS = "getLatestSuccess";
export const GET_MORE_LATEST_SUCCESS = "getMoreLatest";

export const GET_QUIZ_DATA_PENDING = "getQuizDataPending";
export const GET_QUIZ_DATA_SUCCESS  = "getQuizDataSuccess";
export const GET_QUIZ_DATA_ERROR= "getQuizDataError"

export const GET_QUIZ_QUES_DATA_PENDING = "getQuizQuesDataPending";
export const GET_QUIZ_QUES_DATA_SUCCESS  = "getQuizQuesDataSuccess";

export const GET_FILTER_COUNT_DATA_PENDING = "getfilterCountDataPending";
export const GET_FILTER_COUNT_DATA_SUCCESS  = "getfilterCountSuccess";


export const GET_USER_DATA_BY_CUSTOM_URL_PENDING = "getUserDataByCustomUrlPending";
export const GET_USER_DATA_BY_CUSTOM_URL_SUCCESS = "getUserDataByCustomUrlSuccess";
export const RESET_USER_DATA_BY_CUSTOM_URL = "resetUserDataByCustomUrl";

export const RESET_QUIZ_QUES_DATA = 'RESET_QUIZ_QUES_DATA';

export const GET_ASSIGNMENT_QUESION_DATA_PENDING = "getAssignmentQuestionDataPending";
export const GET_ASSIGNMENT_QUESION_DATA_SUCCESS = "getAssignmentQuestionDataSuccess";

export const RESET_FILTER_COUNT_DATA = 'RESET_FILTER_COUNT_DATA';

export const RESET_LATEST_NEWS_DATA = "RESET_LATEST_NEWS_DATA"

/* Mcq Questions Action types here */
export const GET_MCQ_DATA_PENDING = "getMcqQuestionDataPending";
export const GET_MCQ_DATA_SUCCESS  = "getMcqQuestionDataSuccess";
export const GET_MCQ_DATA_ERROR= "getMcqQuestionDataError"



