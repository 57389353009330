import { GET_USER_DATA_BY_CUSTOM_URL_SUCCESS, GET_USER_DATA_BY_CUSTOM_URL_PENDING, RESET_USER_DATA_BY_CUSTOM_URL } from "../../actions/actionTypes";

const initialState = {
    pending: false,
    loadMorePending: false,
    userDatailsData: {},
    error: false,
};

/* reducer function for Quiz data */
export const userDataReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_USER_DATA_BY_CUSTOM_URL_PENDING:
            return {
                ...state,
                pending: true,
            };

        case GET_USER_DATA_BY_CUSTOM_URL_SUCCESS:
            const updatedUserDatails = action.payload.userDatailsData;
           
            return {
                ...state,
                loadMorePending: false,
                userDatailsData: updatedUserDatails
            };

        case RESET_USER_DATA_BY_CUSTOM_URL:
            return {
                ...state,
                userDatailsData: {}
            };

        default:
            return state;
    }
};


/* Action creators for Quiz ques data*/
export const fetchUserDataByCustomUrlPending = () => ({
    type: GET_USER_DATA_BY_CUSTOM_URL_PENDING
});


export const fetchUserDataByCustomUrlSuccess = (userDatailsValue) => ({
    type: GET_USER_DATA_BY_CUSTOM_URL_SUCCESS,
    payload: {
        userDatailsData: userDatailsValue
    }
});

export const resetUserDataByCustomUrl = () => ({
    type: RESET_USER_DATA_BY_CUSTOM_URL
});