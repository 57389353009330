import { GET_QUIZ_QUES_DATA_SUCCESS, GET_QUIZ_QUES_DATA_PENDING, RESET_QUIZ_QUES_DATA } from "../../actions/actionTypes";

const initialState = {
    pending: false,
    loadMorePending: false,
    quizQuesList: {},
    error: false,
  };

/* reducer function for Quiz data */
export const quizQuesReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_QUIZ_QUES_DATA_PENDING:
            return {
                ...state,
                pending: true,
            };

        case GET_QUIZ_QUES_DATA_SUCCESS:
            const newQuizQuesList = action.payload.quizQuesList;
            return {
                ...state,
                loadMorePending: false,
                quizQuesList: newQuizQuesList
            };

        case RESET_QUIZ_QUES_DATA:
            return {
                ...state,
                quizQuesList: {}
            };
                
        default:
            return state;
    }
};


/* Action creators for Quiz ques data*/
export const fetchQuizQuestionsByUserIdPending = () => ({
    type: GET_QUIZ_QUES_DATA_PENDING
});


export const fetchQuizQuestionsByUserIdSuccess = (quizQuesList) => ({
    type: GET_QUIZ_QUES_DATA_SUCCESS,
    payload: {
        quizQuesList: quizQuesList
    }
});

export const resetQuizQuesData = () => ({
    type: RESET_QUIZ_QUES_DATA
});
