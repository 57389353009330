import { GET_QUIZ_DATA_ERROR, GET_QUIZ_DATA_PENDING, GET_QUIZ_DATA_SUCCESS, RESET_QUIZ_DATA } from "../../actions/actionTypes";

const initialState = {
    status: 'idle', 
    pending: false,
    loadMorePending: false,
    quizlist: {},
    error: false,
  };

/* reducer function for Quiz data */
export const quizReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_QUIZ_DATA_PENDING:
            return {
                ...state,
                status: 'loading',
                pending: true,
            };

        case GET_QUIZ_DATA_SUCCESS:
            const newQuizList = action.payload.quizlist;
            return {
                ...state,
                status: 'succeeded', 
                loadMorePending: false,
                quizlist: newQuizList
            };
        case GET_QUIZ_DATA_ERROR:  
            return {
                ...state,
                status: 'failed', 
                pending: false,
                error: action.payload.error,
            }; 
        default:
            return state;
    }
};

/* Action creators for Quiz data*/
export const fetchQuizDataByuserIdPending = () => ({
    type: GET_QUIZ_DATA_PENDING
});

export const fetchQuizDataByuserIdSuccess = (quizlist) => ({
    type: GET_QUIZ_DATA_SUCCESS,
    payload: {
        quizlist: quizlist
    }
});

export const fetchQuizDataByuserIdError = (error) => ({ 
    type: GET_QUIZ_DATA_ERROR,
    payload: {
        error: error,
    },
});

