import { GET_FILTER_COUNT_DATA_PENDING, GET_FILTER_COUNT_DATA_SUCCESS, RESET_FILTER_COUNT_DATA } from "../../actions/actionTypes";

const initialState = {
    pending: false,
    loadMorePending: false,
    filterCountList: {},
  };

/* reducer function for Filter */
export const filterReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_FILTER_COUNT_DATA_PENDING:
            return {
                ...state,
                pending: true,
            };

        case GET_FILTER_COUNT_DATA_SUCCESS:
            const newFilterCountList = action.payload.filterCountList;
            return {
                ...state,
                loadMorePending: false,
                filterCountList: newFilterCountList
            };

        case RESET_FILTER_COUNT_DATA:
            return {
                ...state,
                filterCountList: {}
            };
                
        default:
            return state;
    }
};


/* Action creators for filter count data*/
export const getFilterTypeWiseCountByUserIdPending = () => ({
    type: GET_FILTER_COUNT_DATA_PENDING
});


export const getFilterTypeWiseCountByUserIdSuccess = (filterCountList) => ({
    type: GET_FILTER_COUNT_DATA_SUCCESS,
    payload: {
        filterCountList: filterCountList
    }
});

export const resetFilterData = () => ({
    type: RESET_FILTER_COUNT_DATA
});


